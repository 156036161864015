import type { SlideProps } from '@library/_images/slide-show'

// Time constants
export const HALF_SECOND_MS = 500
export const ONE_SECOND_MS = 1000
export const ONE_MS_SECOND = 0.001
export const ONE_MIN_MS = 60 * 1000
export const TWENTY_MINS_MS = 20 * 60 * 1000
export const TWO_HOURS = 2 * 60 * 60
export const ONE_DAY_IN_SECONDS = 86400
export const ONE_DAY_IN_MS = 86400000
export const NINETY_NINE_DAYS_IN_SECONDS = 8553600
export const SECONDS_IN_MINS = 60
export const MINS_IN_HOUR = 60
export const HOURS_IN_DAY = 24

export const INT_MAX = 2147483647
export const ZERO_MS = 0
export const ZERO = 0
export const ONE = 1
export const FREE_PRICE_AMOUNT = 0
export const FREE_TAG = 'FREE'
export const PAY_WHAT_YOU_CAN_TAG = 'Pay what you can'
export const STRIPE_MINIMUM_PRICE_FOR_CHECKOUT = 50
export const VIDZING_PAGE_TITLE = 'Vidzing'
export const MINIMUM_NETLIFY_REVALIDATE_TIME_SIXTY_SECONDS = 60
export const REVALIDATE_TIME_TEN_MINS = 10 * MINIMUM_NETLIFY_REVALIDATE_TIME_SIXTY_SECONDS
export enum VIDZING_FEE_PERCENTAGES {
  NORMAL = 0.18,
  NOT_FOR_PROFIT = 0.1,
}
export const VIDZING_VIEWER_FEE = 30

export const VIDZING_ACCENT_PLAYFUL_HEX = '#FFBE60'
export const VIDZING_GREY_FIFTY_HEX = '#808080'
export const VIDZING_GREY_SEVENTY_FIVE_HEX = '#454545'
export const VIDZING_SKY_EARTH_GRADIENT = 'var(--vidzingEarth)'

// URL
export const AUTH_URL = '/welcome/enter-email'
export const CREATOR_PORTAL_URL = '/creator'
export const HOUSE_RULES_URL = '/house-rules'
export const VIDZING_EXPLORE_PAGE = '/explore'
export const VIDZING_HOME_PAGE = '/'

// API
export const BASIC_AUTH_TOKEN = 'Basic ' + (process.env.NEXT_PUBLIC_BASIC_AUTH_TOKEN ?? '')
export const BASIC_AUTH_HEADERS = {
  Authorization: BASIC_AUTH_TOKEN,
}

// Public assets
export const SMALL_GRAPHIC_LOGO = '/_logos/vidzing-isologo.svg'
export const FAVICON = '/images/favicon.ico'
export const TEXT_LOGO = '/_logos/vidzing-text-logo.svg'
export const GRAPHIC_TEXT_LOGO = '/_logos/vidzing-graphic-text-logo.svg'

// For tests
export const CHEER_BRANDZ = 'cheerbrandz'
export const NZ_OPERA = 'nzopera'
export const CHANNEL_ID = 'c4778538-f89e-4c17-971a-f5ae5b54564c' // cheerbrandz
export const PAID_CONTENT_ID = '63f75e64-18fe-49c4-ac11-3cf4bea52fb4' // cheerbrandz
export const FREE_CONTENT_ID = '53b7131e-c394-46ca-ac1b-0bd34f7ee0d8' // nzopera
export const BUNDLE_ID = '780c28cd-9076-45e4-96d2-9e25b92e6078'
export const FLEXI_PRICE_CONTENT_ID = '6e72926c-ea2c-4d75-8597-08c51b9299ae' // Flexi Price Test - Dev
export const VIDZING_PROMO_ENTRY_ID = '1_z4k436ss'
export const RAIL_ID = '3f4612ad-409a-4347-af03-99b588513a0a'
export const USER_ID = 'mock-user-id'

export const TEST_USER_EMAIL = 'dapcet@mailpoof.com'
export const TEST_USER_PASSWORD = '1234567abc#I*('
export const TEST_USER_FIRSTNAME = 'firstName'
export const TEST_USER_LASTNAME = 'lastName'
export const TEST_USER_DAY = '01'
export const TEST_USER_MONTH = '01'
export const TEST_USER_YEAR = '2000'
export const TEST_USER_GENDER = 'prefer not to say'
export const TEST_USER_MARKETING = true

// Image sizes
export const DEFAULT_WIDTH_THUMB = 800

export const DEFAULT_WIDTH_CREATOR_THUMB = 200
export const BUNDLE_CONTENT_THUMB = 200
export const CARD_WIDTH_THUMB = 300
export const CREATOR_LOGO_WIDTH_THUMB = 120
export const AVATAR_CHANNEL_THUMB = 380
export const RAIL_PRIMARY_WIDTH_THUMB = 600
export const RAIL_SECONDARY_WIDTH_THUMB = 450
export const DIALOG_WIDTH_THUMB = 600
export const BUNDLE_WIDTH_THUMB = 100
export const CHAPTER_CONTENT_THUMB = 200

export const RECOMMENDED_CONTENT_THUMB = 200

// Quality
export const DEFAULT_QUALITY_IMG = 'auto:eco'

// Terms
export const ACCESS_TO_ALL = 'Access to all channel content'

/* Rails Settings */
export const RAILS_TILE_TO_SCROLL = 2

// Creators Rail
export const DEFAULT_CREATOR_RAIL_TAILS = 11
export const CREATOR_RAIL_RESPONSIVE = [
  {
    breakpoint: 3000,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 7,
    },
  },
  {
    breakpoint: 2700,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 7,
    },
  },
  {
    breakpoint: 2290,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 2150,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 1750,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 1560,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 1273,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 1084,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 764,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
]

// Facebook domain verification
export const FACEBOOK_DOMAIN_VERIFICATION = 'ekoonvrfwv8m3logrr9rds4l6zvjh9'

// Primary Rail
export const DEFAULT_PRIMARY_RAIL_TAILS = 1

//Secondary Rail
export const DEFAULT_SECONDARY_RAIL_TAILS = 10
export const SECONDARY_RAIL_RESPONSIVE = [
  {
    breakpoint: 3075,
    settings: {
      slidesToShow: 9,
      slidesToScroll: 9,
    },
  },
  {
    breakpoint: 2817,
    settings: {
      slidesToShow: 8,
      slidesToScroll: 8,
    },
  },
  {
    breakpoint: 2613,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 7,
    },
  },
  {
    breakpoint: 2293,
    settings: {
      slidesToShow: 5,
      variableWidth: true,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 1750,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      variableWidth: true,
    },
  },
  {
    breakpoint: 1560,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 1515,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 4,
      variableWidth: true,
    },
  },
  {
    breakpoint: 1300,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 4,
      variableWidth: true,
    },
  },
  {
    breakpoint: 1244,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      variableWidth: true,
    },
  },
  {
    breakpoint: 975,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      variableWidth: true,
    },
  },
  {
    breakpoint: 573,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    },
  },
]

// Home hero images
export const homepageHeadSlides: SlideProps[] = [
  {
    alt: 'Band',
    src: '/_hero/Vidzing_HeroImg-band_nvtftl.png',
  },
  {
    alt: 'Yoga',
    src: '/_hero/Vidzing_HeroImg_yoga_z9zxo5.png',
  },
  {
    alt: 'Chef',
    src: '/_hero/Vidzing_HeroImg_chef_igfmvs.png',
  },
  {
    alt: 'Ballet',
    src: '/_hero/Vidzing_HeroImg_ballet_zs7ips.png',
  },
  {
    alt: 'Influencer',
    src: '/_hero/Vidzing_HeroImg_influencer_keqar4.png',
  },
]

//
export const ONE_AND_A_HALF_SECONDS_IN_MS = 1500
export const TEN_SECONDS_IN_MS = 10000

export const PLAYER_PATHNAME = 'watch'

// Me Menu
export const SHOW_MENU_MOBILE_UNDER = 767
export const VIDZING_VERSION = '1.0203.202'

// Geo blocking texts
export const GEO_NOT_AVAILABLE_IN_YOUR_COUNTRY = 'This stream is not available in your region'
export const GEO_MODAL_TITLE = 'This stream has restricted access.'

export const VIDZING_SUPPORT_EMAIL = 'support@vidzing.tv'
export const VIDZING_SUPPORT_LINK = `mailto:${VIDZING_SUPPORT_EMAIL}`

export type VidzingPageState = 'loading' | 'access' | 'denied'

export const ON_MOBILE_AND_TABLET_UNDER = 1024
export const ON_LARGE_LAPTOP_AND_BELLOW = 1138

// Guest user constants
export const GUEST_USER = 'guest_user'

export const PURCHASE_OPTION_UNAVAILABLE = 'Purchase option unavailable'
