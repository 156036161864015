import axiosRequest from '@apis/axios'
import {
  SubmitExpectedVideoReviewDateInterface,
  VideoReviewInterface,
} from '@shared/interfaces/content/video-review.interface'

export default async function submitExpectedDate(
  payload: SubmitExpectedVideoReviewDateInterface,
): Promise<VideoReviewInterface> {
  const { contentId, videoReviewRequest, estimatedSubmissionDate } = payload
  const { videoReviewRequestId } = videoReviewRequest

  const requestUrl = `/video-review/content/${contentId}/request/${videoReviewRequestId}/estimated-submission-date`

  return (await axiosRequest(
    'contents',
    {
      method: 'PATCH',
      url: requestUrl,
      data: {
        estimatedSubmissionDate,
      },
    },
    true,
  )) as VideoReviewInterface
}
