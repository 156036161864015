import {
  vidzingApiConfig,
  vidzingWebConfig,
  VIDZING_API_LOCAL_DOMAIN,
  VIDZING_DOMAIN,
} from './backend.microservices.data'
import { datadogLogs } from '@datadog/browser-logs'
export type AppEnvironments = 'local' | 'prod' | 'dev' | 'uat' | 'prev'
export type MicroserviceNames =
  | 'users'
  | 'creators'
  | 'billing'
  | 'contents'
  | 'rails'
  | 'analytics'
  | 'assets'
  | 'marketing'
  | 'messaging'
  | 'bundles'
  | 'payments'

export class ApiURLDetails {
  private readonly DEFAULT_LIVE_PORT = 3000
  public appEnv: AppEnvironments
  public appFrontendEnv: AppEnvironments

  constructor(environment: AppEnvironments, frontendEnv: AppEnvironments) {
    this.appEnv = environment
    this.appFrontendEnv = frontendEnv
  }

  /**
   * Gets the full url of a microservice.
   * NOTE: a microservice, such as Billing, can hold more than one controller.
   * So, for Billing, we have a controller (resource name) /payments and /subscriptions, etc
   * @param microserviceName the name of the microservice
   * @param controllerName (Optional) the name of the controller for that microservice.
   *
   */
  getUrl = (microserviceName: MicroserviceNames, controllerName?: string): string => {
    try {
      const path = controllerName ? controllerName : microserviceName
      let url: string
      if (this.appEnv === 'local') {
        // if param controllerName exists, then path equals controllerName, if not, path equals microserviceName
        // this is for those scenarios where a microservice holds more than one controller (such as Billing)
        url = `http://${VIDZING_API_LOCAL_DOMAIN}:${vidzingApiConfig[microserviceName].port}${vidzingApiConfig[microserviceName].paths[path]}`
        return url
      }
      // TODO the `v1` should be handled differently in the future so we can serve different versions. Not important right now though.
      url = `https://${this.appEnv}.${process.env.API_DOMAIN_SUFFIX}/v1${vidzingApiConfig[microserviceName].paths[path]}`

      return url
    } catch (error) {
      datadogLogs.logger.error('Error getting api url', error)
      throw error
    }
  }

  /**
   * Gets the port that the nodejs server will use to start the service.
   * For live environments, all service will use port 3000.
   * For local development, each service use a different port to avoid port conflicts on the developer's machine
   * @param microserviceName
   */
  getPort(microserviceName: MicroserviceNames): number {
    try {
      let port: number
      if (this.appEnv === 'local') {
        port = vidzingApiConfig[microserviceName].port
        return port
      }
      return this.DEFAULT_LIVE_PORT
    } catch (error) {
      datadogLogs.logger.error('Error getting api port', error)
      throw error
    }
  }

  getWebDomain(): string {
    try {
      return `${vidzingWebConfig[this.appFrontendEnv]}`
    } catch (error) {
      datadogLogs.logger.error('Error getting web domain', error)
      throw error
    }
  }

  getSseConnectionUrl(microserviceName: MicroserviceNames, controllerName?: string): string {
    try {
      const path = controllerName ? controllerName : microserviceName
      if (this.appEnv === 'local') {
        return `//${VIDZING_API_LOCAL_DOMAIN}:${vidzingApiConfig[microserviceName].port}${vidzingApiConfig[microserviceName].paths[path]}`
      }
      switch (microserviceName) {
        case 'messaging':
          return `https://${this.appEnv}.sse.${VIDZING_DOMAIN}${vidzingApiConfig[microserviceName].paths[path]}`
        default:
          return `https://${this.appEnv}.${process.env.API_DOMAIN_SUFFIX}/v1${vidzingApiConfig[microserviceName].paths[path]}`
      }
    } catch (error) {
      datadogLogs.logger.error('Error getting sse connection url', {
        microserviceName,
        controllerName,
        error,
      })
      throw error
    }
  }
}

const apiDetails = new ApiURLDetails(
  process.env.ENVIRONMENT as AppEnvironments,
  process.env.FRONT_END_ENV as AppEnvironments,
)

export const APIDETAILS = {
  port: apiDetails.getPort('users'),
  usersUrl: apiDetails.getUrl('users'),
  contentsUrl: apiDetails.getUrl('contents'),
  creatorsUrl: apiDetails.getUrl('creators'),
  billingUrl: apiDetails.getUrl('billing', 'payments'),
  railsUrl: apiDetails.getUrl('rails'),
  analyticsUrl: apiDetails.getUrl('analytics'),
  assetsUrl: apiDetails.getUrl('assets'),
  marketingUrl: apiDetails.getUrl('marketing'),
  bundlesUrl: apiDetails.getUrl('bundles'),
  paymentsUrl: apiDetails.getUrl('payments'),
  webDomain: apiDetails.getWebDomain(),
  sseUrl: apiDetails.getSseConnectionUrl('messaging', 'sse'),
}
