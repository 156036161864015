import { UserVideoReviewsStateInterface } from '@shared/interfaces/content/video-review.interface'
import { createSlice } from '@reduxjs/toolkit'
import {
  fetchUserVideoReviewsThunk,
  submitExpectedDateThunk,
  submitVideoReviewThunk,
} from '@store/video-review/_thunk'
import {
  fetchUserVideoReviewsFailureReducer,
  fetchUserVideoReviewsReducer,
  fetchUserVideoReviewsSuccessReducer,
  submitExpectedReviewDate,
  submitVideoReviewFailureReducer,
  submitVideoReviewReducer,
  submitVideoReviewSuccessReducer,
} from '@store/video-review/_reducer'

const initialState: UserVideoReviewsStateInterface = {
  fetchStatus: 'idle',
  videoReviewsRequest: [],
}

const videoReviewSlice = createSlice({
  name: 'videoReview',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserVideoReviewsThunk.pending.toString()]: fetchUserVideoReviewsReducer,
    [fetchUserVideoReviewsThunk.fulfilled.toString()]: fetchUserVideoReviewsSuccessReducer,
    [fetchUserVideoReviewsThunk.rejected.toString()]: fetchUserVideoReviewsFailureReducer,
    // Submit review
    [submitVideoReviewThunk.pending.toString()]: submitVideoReviewReducer,
    [submitVideoReviewThunk.fulfilled.toString()]: submitVideoReviewSuccessReducer,
    [submitVideoReviewThunk.rejected.toString()]: submitVideoReviewFailureReducer,
    // Expected date
    [submitExpectedDateThunk.pending.toString()]: submitExpectedReviewDate,
    [submitExpectedDateThunk.fulfilled.toString()]: submitVideoReviewSuccessReducer,
    [submitExpectedDateThunk.rejected.toString()]: submitVideoReviewFailureReducer,
  },
})

export const videoReviewReducer = videoReviewSlice.reducer
