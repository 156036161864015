import axiosRequest from '@apis/axios'
import {
  SubmitVideoReviewRequestInterface,
  VideoReviewFetchRequestsInterface,
  VideoReviewInterface,
} from '@shared/interfaces/content/video-review.interface'

export default async function submitVideoReview(
  payload: SubmitVideoReviewRequestInterface,
): Promise<VideoReviewInterface> {
  return (await axiosRequest(
    'contents',
    {
      method: 'PUT',
      url: `/video-review/user-submission`,
      data: {
        ...payload,
      },
    },
    true,
  )) as VideoReviewInterface
}
