import axiosRequest from '@apis/axios'
import {
  VideoReviewFetchRequestsInterface,
  VideoReviewInterface,
} from '@shared/interfaces/content/video-review.interface'

export default async function fetchVideoReviewsRequests({
  contentId,
  organisationId,
}: VideoReviewFetchRequestsInterface): Promise<VideoReviewInterface[]> {
  return (await axiosRequest(
    'contents',
    {
      method: 'GET',
      url: `video-review/content/${contentId}/requests/user`,
    },
    true,
  )) as VideoReviewInterface[]
}
