import { APIDETAILS, MicroserviceNames } from '../_utilities/environment/api.url.details'
import { BASIC_AUTH_TOKEN } from '../../constants'
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import fbAuth from '@apis/firebase'
import { datadogLogs } from '@datadog/browser-logs'
import { isEmptyString } from '@utils/strings'

const onSuccess = (response: AxiosResponse<object>): object => {
  datadogLogs.logger.info('Successful Frontend API request', {
    context: { response },
  })
  return response.data
}

const onError = (error: AxiosError<object>) => {
  datadogLogs.logger.error('Failed Frontend API request', {
    context: { error },
  })
  return Promise.reject(error.response)
}

const baseUrl = (microserviceName: MicroserviceNames): string => {
  switch (microserviceName) {
    case 'users':
      return APIDETAILS.usersUrl
    case 'contents':
      return APIDETAILS.contentsUrl
    case 'creators':
      return APIDETAILS.creatorsUrl
    case 'billing':
      return APIDETAILS.billingUrl
    case 'rails':
      return APIDETAILS.railsUrl
    case 'analytics':
      return APIDETAILS.analyticsUrl
    case 'assets':
      return APIDETAILS.assetsUrl
    case 'marketing':
      return APIDETAILS.marketingUrl
    case 'bundles':
      return APIDETAILS.bundlesUrl
    case 'payments':
      return APIDETAILS.paymentsUrl
  }
}

/**
 * Custom Axios Error class
 * @param axiosError the Axios error object
 * @returns {VidzingAxiosError} - A VidzingAxiosError object
 */
class VidzingAxiosError extends Error {
  public response: any
  public code: string | undefined

  constructor(axiosError: AxiosError) {
    super('Failed Frontend API request')
    this.name = axiosError.name
    this.message = axiosError.message
    this.response = axiosError.response?.data
    this.code = axiosError.code
  }
}

/**
 * Axios request wrapper for Frontend API calls to other microservices
 * @template T the expected response type (generic type) - defaults to Record<string, unknown> (If you know the Type of the response, then specify it when calling the function, eg: axiosRequest<YourType>(...))
 * @param microserviceName the name of the microservice to call
 * @param options the Axios request configuration
 * @param forceToken (Optional) whether to force the request to use the token or not
 * @param version (Optional) endpoint version to use. By default, 1
 * @returns {Promise<T>} - A Promise that resolves to the response data of type T.
 * @throws an error if the request fails
 */
export default async function axiosRequest<T = Record<string, unknown>>(
  microserviceName: MicroserviceNames,
  options: AxiosRequestConfig,
  forceToken = false,
  version = '',
): Promise<T> {
  try {
    const token = await fbAuth.currentUser?.getIdToken()
    const authOptional = token ? `Bearer ${token}` : BASIC_AUTH_TOKEN

    // Base headers
    let headers: AxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: forceToken ? `Bearer ${token}` : authOptional,
      // include other headers here
    }

    // Passing endpoint version
    if (!isEmptyString(version)) {
      headers = { ...headers, 'endpoint-version': version }
    }

    const instance = axios.create({
      baseURL: baseUrl(microserviceName),
      headers,
      responseType: 'json',
      // include other Axios configuration options here
    })

    const response: AxiosResponse<T> = await instance(options)

    try {
      datadogLogs.logger.info('Successful Frontend API request', {
        request: options,
        response: response.data,
      })
    } catch (error) {
      // When we can't use datadog. I'm going to use a console.error
      console.error(error)
    }

    return response.data
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error)
    } else {
      handleNonAxiosError(error)
    }
    throw error
  }

  function handleAxiosError(error: any) {
    const env = process.env.NODE_ENV as string
    const vidzingAxiosError = env !== 'development' ? new VidzingAxiosError(error) : error

    const errorObject = {
      message: vidzingAxiosError?.message,
      context: { response: vidzingAxiosError?.response },
      error: vidzingAxiosError,
      code: vidzingAxiosError?.code,
    }

    if ([401, 403, 404, 400, 204].includes(vidzingAxiosError.response?.status)) {
      // These are normal behavior errors; log them as info.
      datadogLogs.logger.info('Expected failed Frontend API request', errorObject)
    } else {
      // This is a critical error; log it as an error.
      datadogLogs.logger.error('Failed Frontend API request', errorObject)
    }

    throw vidzingAxiosError
  }

  function handleNonAxiosError(error: any) {
    datadogLogs.logger.error(error?.message || 'Failed Frontend API request', error)
    throw error
  }
}
