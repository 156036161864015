import type { RootState } from '..'
import type { FetchStatus } from '../_types'
import { UserEntity } from '@apis/users-microservice/_types'
import { createSelector } from 'reselect'
import { BundleState } from '@store/bundle-slice/_types'
import { ContentEntity } from '@apis/contents-microservice/_entities/_types'
import { isEmptyArray } from '@utils/arrays'

export const selectStatus = ({ user }: RootState): FetchStatus | undefined => {
  return user.fetchStatus
}

export const selectUserId = ({ user }: RootState): string | undefined => user.userId

export const selectUserProps =
  <PropKey extends keyof UserEntity>(propKey: PropKey) =>
  ({ user }: RootState): UserEntity[PropKey] | undefined => {
    // If property value is null, return undefined
    return user?.[propKey] ?? undefined
  }

export const selectWatching = ({ user }: RootState): number | undefined => user.watching

export const selectTooManyUsersWatching = ({ user }: RootState): boolean | undefined =>
  user.tooManyUsersWatching

export const selectEmail = ({ user }: RootState): RootState['user']['email'] => user.email

export const selectUser = createSelector(
  (state: RootState) => state.user,
  (user: UserEntity) => user,
)
