import { FetchStatus } from '@store/_types'

export type FirebaseConfig = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

// Data inputed by users from UI
export type SignUpData = {
  email: string
  password: string
  firstName: string
  lastName: string
  day: string
  month: string
  year: string
  gender: string
  marketingSubscribed: boolean
  continueUrl?: string
  channelId?: string
}

// Activation account
export type ActivationAccount = {
  email: string
  password: string
  firstName?: string
  lastName?: string
  day?: string
  month?: string
  year?: string
  gender?: string
  marketingSubscribed?: boolean
  emailVerified?: boolean
  dateOfBirth?: Date
}

export type LoginData = {
  email: string
  password: string
  isStayLoggedIn: boolean
}

export type ProfileData = {
  firstName?: string
  lastName?: string
  day?: string
  month?: string
  year?: string
  gender?: string
  marketingSubscribed?: boolean
  password?: string
}

// Data from server
export type UserEntity = {
  administrativeArea: string
  country: string
  dateOfBirth: Date
  email: string
  emailVerified: boolean
  firebaseUid: string
  firstName: string
  fetchStatus: FetchStatus
  gender: string
  lastName: string
  locality: string
  marketingSubscribed: boolean
  stripeToken: string
  thoroughfare: string
  tooManyUsersWatching: boolean
  userId: string
  userType: UserTypeEnum
  watching: number
}

export enum UserTypeEnum {
  CREATOR = 'creator',
  ADMIN = 'admin',
  VIEWER = 'viewer',
}

export type ProfileCompletionJson = {
  isCompleted: boolean
}

export type UserAccountStatus = {
  signedUp: boolean
  activated: boolean
  user: UserEntity
}

export type PlayerConcurrencyResponse = {
  userId: string
  concurrency: number
  timeStarted?: number
}

export type AccountActivation = {
  email: string
  code: string
}
