import { subscribersReducer } from './subscriber-slice'
import { contentsReducer } from './contents-slice'
import { configureStore } from '@reduxjs/toolkit'
import { channelsReducer } from './channel-slice'
import { railsReducer } from './rails-slice'
import { userReducer } from './user-slice'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { webSocketReducer } from './websocket-slice'
import { geoReducer } from './geo-slice'
import { cartReducer } from './cart-slice'
import { creatorReducer } from '@store/creator-slice'
import { customChannelReducer } from '@store/custom-channel'
import { playerReducer } from '@store/player-slice'
import { modalReducer } from '@store/modal-slice'
import { assetsReducer } from '@store/asset-slice'
import { passesReducer } from '@store/pass-slice'
import { organisationsReducer } from '@store/organisation-slice'
import { searchReducer } from '@store/search-slice'
import { userBillingReducer } from '@store/user-billing-slice'
import { userBillingInvoicesReducer } from '@store/user-billing-slice/invoices'
import { userBillingPaymentMethodReducer } from '@store/user-billing-slice/payment-methods'
import { themeReducer } from '@store/theme-slice'
import { bundleReducer } from '@store/bundle-slice'
import { featureFlagReducer } from '@store/feature-flag-slice'
import { userBillingPurchasesReducer } from '@store/user-billing-slice/purchases'
import { userBillingActivePlansReducer } from '@store/user-billing-slice/active-plans'
import { couponsReducer } from '@store/coupons-slice'
import { tagsReducer } from '@store/tags-slice'
import { videoReviewReducer } from '@store/video-review'

const store = configureStore({
  reducer: {
    // app: appReducer,
    userBillingPaymentMethods: userBillingPaymentMethodReducer,
    userBillingPurchases: userBillingPurchasesReducer,
    userBillingActivePlans: userBillingActivePlansReducer,
    userBillingInvoices: userBillingInvoicesReducer,
    userBilling: userBillingReducer,
    subscribers: subscribersReducer,
    contents: contentsReducer,
    channels: channelsReducer,
    user: userReducer,
    webSockets: webSocketReducer,
    rails: railsReducer,
    geo: geoReducer,
    cart: cartReducer,
    creator: creatorReducer,
    customChannel: customChannelReducer,
    player: playerReducer,
    modal: modalReducer,
    assets: assetsReducer,
    passes: passesReducer,
    organisations: organisationsReducer,
    search: searchReducer,
    theme: themeReducer,
    bundles: bundleReducer,
    featureFlags: featureFlagReducer,
    coupons: couponsReducer,
    tags: tagsReducer,
    videoReview: videoReviewReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
