import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  FETCH_USER_VIDEO_REVIEW,
  SUBMIT_EXPECTED_DATE,
  SUBMIT_VIDEO_REVIEW,
} from '@store/video-review/_constants'
import { datadogLogs } from '@datadog/browser-logs'
import {
  SubmitExpectedVideoReviewDateInterface,
  SubmitVideoReviewRequestInterface,
  VideoReviewFetchRequestsInterface,
} from '@shared/interfaces/content/video-review.interface'
import fetchVideoReviewsRequests from '@apis/video-review/fetch-requests'
import submitVideoReview from '@apis/video-review/submit-video-review'
import submitExpectedDate from '@apis/video-review/submit-expected-date'

export const fetchUserVideoReviewsThunk = createAsyncThunk(
  FETCH_USER_VIDEO_REVIEW,
  async ({ contentId, organisationId }: VideoReviewFetchRequestsInterface, thunkAPI) => {
    try {
      const requests = await fetchVideoReviewsRequests({ contentId, organisationId })
      return {
        contentId,
        requests,
      }
    } catch (error) {
      datadogLogs.logger.error(error?.message || `Error retrieving content bundle`, error)
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    }
  },
)

export const submitVideoReviewThunk = createAsyncThunk(
  SUBMIT_VIDEO_REVIEW,
  async (payload: SubmitVideoReviewRequestInterface, thunkAPI) => {
    try {
      const response = await submitVideoReview(payload)
      return {
        contentId: payload.contentId,
        videoReviewRequest: response,
      }
    } catch (error) {
      datadogLogs.logger.error(error?.message || `Error retrieving content bundle`, error)
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    }
  },
)

export const submitExpectedDateThunk = createAsyncThunk(
  SUBMIT_EXPECTED_DATE,
  async (payload: SubmitExpectedVideoReviewDateInterface, thunkAPI) => {
    try {
      const response = await submitExpectedDate(payload)
      return {
        contentId: payload.contentId,
        videoReviewRequest: response,
      }
    } catch (error) {
      datadogLogs.logger.error(error?.message || `Error retrieving content bundle`, error)
      return thunkAPI.rejectWithValue(error?.data?.message ?? 'Error')
    }
  },
)
