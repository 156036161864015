interface ApiConfigInterface {
  [microservice: string]: { port: number; paths: { [path: string]: string }; name: string }
}
export const vidzingApiConfig: ApiConfigInterface = {
  users: {
    name: 'users',
    port: 3010,
    paths: {
      users: '/users',
    },
  },
  creators: {
    name: 'creators',
    port: 3020,
    paths: {
      creators: '/creators',
    },
  },
  billing: {
    name: 'billing',
    port: 3030,
    paths: {
      payments: '/payments',
    },
  },
  contents: {
    name: 'contents',
    port: 3040,
    paths: {
      contents: '/contents',
    },
  },
  rails: {
    name: 'rails',
    port: 3040,
    paths: {
      rails: '/rails',
    },
  },
  analytics: {
    name: 'analytics',
    port: 3060,
    paths: {
      analytics: '/analytics',
    },
  },
  assets: {
    name: 'assets',
    port: 3050,
    paths: {
      assets: '/assets',
    },
  },
  marketing: {
    name: 'marketing',
    port: 3080,
    paths: {
      marketing: '/marketing',
    },
  },
  messaging: {
    name: 'messaging',
    port: 3050,
    paths: {
      sse: '/sse',
      mailing: '/mailing',
    },
  },
  bundles: {
    name: 'bundles',
    port: 3040,
    paths: {
      bundles: '/bundles',
    },
  },
  payments: {
    name: 'payments',
    port: 3030,
    paths: {
      payments: '/payments',
    },
  },
}

export const VIDZING_DOMAIN = 'vidzing.tv'
export const VIDZING_API_LOCAL_DOMAIN = 'localhost'

export const vidzingWebConfig = {
  local: 'http://localhost:3000',
  uat: `https://uat.${VIDZING_DOMAIN}`,
  prev: `https://deploy-preview-${process.env.REVIEW_ID}--vidzing.netlify.app/`,
  prod: `https://${VIDZING_DOMAIN}`,
  dev: `https://develop.${VIDZING_DOMAIN}/`,
}
